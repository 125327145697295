<template>
  <div>
      <v-card flat class="ma-auto" max-width="600">
          <v-card-title>
              {{localizedText[language].myAddresses}}
          </v-card-title>
          <AddressBookComponent :isCheckout="false" />
      </v-card>
      
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AddressBookComponent from "@/components/AddressBookComponent";
export default {
name: "MyAddressBook",
components:{AddressBookComponent},
//********************** */
  computed: {
    ...mapGetters("Account", {
      isLoggedIn: "isLoggedIn",
      language: "language",
      isRegistered: "isRegistered",
      currencyCode: "currencyCode",
      currency: "currency",
    }),

  },
//********************** */
  data: () => ({
 
    localizedText: {
      en: {
        myAddresses: "My address book",

        
      },
      ar: {
           myAddresses: "سجل العناوين",
       
      },
    }
  }),
}
</script>

<style>

</style>